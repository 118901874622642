// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

@media (hover: hover) {
  a {
    &:hover {
      color: $accent-color;
    }
  }
}
@media (min-width: 1330px) {
  .container {
    max-width: 1980px;
  }
}

@media (min-width: 1430px) {
  .container {
    max-width: 1280px;
  }
}
input[type="text"],
input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
a[type="button"] {
  -webkit-appearance: none;
}
.text-color-theme {
  color: $accent-color;
  fill: $accent-color;
}
.text-medium-gray {
  color: $medium-gray;
}
.c-pointer {
  cursor: pointer;
}
.background-gray {
  background-color: #f5f5f5;
}
.link-button {
  cursor: pointer;
  color: $accent-color;
  &:hover {
    text-decoration: underline;
  }
}
.hover12 figure {
  background: $accent-color;
  border: $round-border;
  margin: 0 0 0;
}
.hover12 figure img {
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.hover12 figure:hover img {
  opacity: 0.5;
}
.react-tel-input {
  .form-control {
    width: 100% !important;
    border-radius: 2px !important;
    height: inherit;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #dc3545;
  }
}
.tnC-content {
  padding: 10px 25px;
  height: 420px;
  overflow: auto;
  ol {
    padding-inline-start: 18px;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
}
.slider-arrow {
  position: absolute;
  z-index: 5;
  // top: 18%;
  padding: 13px;
  cursor: pointer;
  fill: $accent-color;

  svg {
    width: 20px;
    height: 20px;
    position: relative;
  }

  &.left {
    left: 35px;
    svg {
      left: -1px;
    }
  }
  &.right {
    right: 35px;
    svg {
      right: -1px;
    }
  }

  @media (max-width: 579px) {
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
}

button:focus,
.btn.focus,
.btn:focus {
  &.btn {
    outline: none;
    box-shadow: none;
  }
}

.load-more-btn {
  outline: none !important;
  color: $accent-color;
  display: inline-flex;
  background-color: #ffffff;
  padding: 6px 20px;
  border: 1px solid $card-border-color;
  align-items: center;
  transition: all $local-transition-duration ease-in-out;

  svg {
    fill: $accent-color;
    margin-right: 10px;
  }

  &:hover {
    color: #ffffff;
    display: inline-flex;
    background-color: $accent-color;
    svg {
      fill: #ffffff;
    }
  }
}
.round-label {
  background: #808080;
  color: #fff;
  width: 100px;
  border-radius: 13px;
  height: auto;
  font-size: 13px;
  padding: 3px;
  text-align: center;
  margin-left: auto;
}
.light-background-section {
  background-color: $background-gray;
  padding: 80px 15px;
  @media (max-width: 576px) {
    padding-top: 20px;
  }
}
.title1 .title-inner1 {
  font-size: 25px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
  text-transform: none;
  color: #2e2e2e;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  @media (max-width: 580px) {
    font-size: 21px;
  }
}
.title1 .title-inner1:after {
  position: absolute;
  height: 5px;
  width: 70px;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
}
/** for mobile **/
@media (max-width: 576px) {
  .categories-img {
    padding: 10px;
  }
  .categories-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 120px;
    height: 35px;
    font-size: 11px;
  }
  .material-container {
    margin-bottom: 20px;
  }
  .material-row {
    margin-bottom: 0px;
  }
}

/** for tablet **/
@media (min-width: 641px) and (max-width: 1024px) {
  .material-container {
    margin-bottom: 20px;
  }
  .material-row {
    margin-bottom: 0px;
  }
}

.home-category-box {
  position: relative;
  height: 100%;
  margin-bottom: 15px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .title-box {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}

.slider-arrow-circle {
  position: absolute;
  z-index: 5;
  top: 30px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.6;
  padding: 8px;
  cursor: pointer;
  fill: #393b3b;

  svg {
    width: 13px;
    height: 13px;
    position: relative;
  }

  &.left {
    left: -15px;
    box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2);
    svg {
      left: -1px;
    }
  }
  &.right {
    right: -15px;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
    svg {
      right: -1px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.vproduct-section-2 .container {
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
.vproduct-section-2 {
  background-color: #fff;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 10px 50px 10px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 580px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.vbrands-section .container {
  padding: 30px;
  @media (max-width: 1200px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 30px;
  }
  @media (max-width: 580px) {
    max-width: 100%;
    padding: 20px;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
}
.vproduct-section-3 {
  // display: flex;
  justify-content: center;
}
.vproduct-section-3 {
  .category-box {
    background: #fafafa;
    width: calc(20% - 10px);
    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
      width: calc(25% - 10px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
      width: calc(33.33333% - 10px);
    }
    @media (max-width: breakpoint(sm-end)) {
      width: calc(50% - 10px);
    }
  }
  &.grid-3 {
    .category-box {
      background: #fafafa;
      width: calc(33.33333% - 10px);
      @media (max-width: breakpoint(sm-end)) {
        width: calc(50% - 10px);
      }
    }
  }
}
.vproduct-section-3 .category-box,
.vbrands-section .brand-box {
  border: 1px solid #e8e8e8;
  margin: 0 5px;
  margin-bottom: 10px;
  transition: 0.3s;
  border-radius: $product-card-border-radius;
}
.vproduct-section-3 .category-box:hover,
.vbrands-section .brand-box:hover {
  box-shadow: 0 0 8px 2px #bfbfbf;
  @media (max-width: 1200px) {
    box-shadow: 0 0 6px 1px #bfbfbf;
  }
}
.category-box .img-wrapper {
  position: relative;
  height: 210px;
  width: 100%;
  border-top-left-radius: $product-card-border-radius;
  border-top-right-radius: $product-card-border-radius;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-top-left-radius: $product-card-border-radius;
    border-top-right-radius: $product-card-border-radius;
  }
  @media (max-width: 480px) {
    height: 170px;
  }
}
.brands-box {
  width: 100%;
  padding: 5px 10px;
  .img-wrapper {
    height: 85px;
    img {
      object-fit: contain;
    }
  }
}
.vbrands-section .item-detail {
  text-align: center;
  padding: 5px;
  a {
    color: #2e2e2e;
    overflow-wrap: anywhere;
  }
}
.vproduct-section-3 .item-detail {
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  color: #7a7a7a;
  transition: all 0.5s ease;

  a {
    color: #2e2e2e;
    overflow: hidden;
    flex-grow: 1;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.banner-container {
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px;
  }
}
.no-result-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infinite-scroll-component {
  overflow: hidden !important;
}
.wyswyg_title {
  text-align: center;
  padding-top: 30px;
}
.wyswyg-wrapper {
  padding: 60px 20px;
}
.wyswyg-section {
  padding: 40px 10px;
}
.react-page-cell {
  display: initial;
}
.slick-track {
  margin-left: 0;
}
.section-title {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 567px) {
    font-size: 23px;
  }
}
.password-eye {
  cursor: pointer;
  float: right;
  margin-right: 6px;
  margin-top: -26px;
  position: relative;
  z-index: 2;
}
.modal-close-btn {
  z-index: 99;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: $accent-color;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  svg {
    fill: white;
  }
}
.vbrands-section {
  padding-top: 30px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 580px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.vbrands-section .container {
  padding: 40px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 30px;
  }
}
.vproduct-section-3 .container,
.vbrands-section .container {
  padding: 40px;
  @media (max-width: 1200px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 30px;
  }
  @media (max-width: 580px) {
    max-width: 100%;
    padding: 20px;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
}
.vbrands-section .brand-box:hover {
  box-shadow: 0 0 12px 4px #bfbfbf;
  @media (max-width: 1200px) {
    box-shadow: 0 0 8px 2px #bfbfbf;
  }
  .item-detail {
    text-align: center;
    padding: 5px;
    a {
      color: #2e2e2e;
    }
  }
}
.brands-box {
  padding: 5px 10px;
  .img-wrapper {
    position: relative;
    width: 100%;
    height: 85px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    @media (max-width: 1200px) {
      height: 220px;
    }
    @media (max-width: 580px) {
      height: 180px;
    }
  }
}
.sort-name-line {
  background-color: #e5e5e5;
  height: 20px;
  .circle-sort-name {
    background-color: $accent-color;
    padding: 4px 10px;
    color: #fff;
    border-radius: 50%;
    height: 35px;
    width: 36px;
    z-index: 9;
    position: relative;
    left: 15px;
    top: -6px;
  }
}
.scroll-list-container {
  overflow-y: auto;
  max-height: 500px;
  padding: 10px;
}
.delivery-method-card {
  padding: 10px 70px;
  align-items: center;
  img {
    height: 100px;
    width: 100px;
  }
  @media (max-width: 992px) {
    padding: 10px 40px;
  }
  @media (max-width: 780px) {
    padding: 10px 20px;
    img {
      height: auto;
      width: 80%;
    }
  }
}
.voucher-box {
  color: #fff;
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;
  .left {
    border-radius: 8px;
    background-color: #ff7e36;
    width: 30%;
    text-align: center;
    padding: 10px;
    img {
      height: 90px;
      width: 100%;
      object-fit: contain;
    }
  }
  .right {
    width: 70%;
    border-radius: 8px;
    background-color: #ff7e36;
    display: flex;
    align-items: center;
    // border-left: dotted;
    // border-color: #fff;
    background-image: linear-gradient(to bottom, #fff 40%, rgba(255, 255, 255, 0) 10%);
    background-position: left;
    background-size: 2px 20px;
    background-repeat: repeat-y;
    padding: 10px;
    padding-left: 10%;
    .name {
      // text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
    }
  }
  .tnc {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
    z-index: 10;
  }
  &.inactive {
    cursor: default;
    .left {
      background-color: grey;
    }
    .right {
      background-color: grey;
    }
  }
}
.rating-star {
  color: #ffa200;
  &.small {
    font-size: 5px;
  }
}
.feedback-type-box__list {
  .feedback-type-box {
    margin: 0 10px 8px 0;
  }
}
.feedback-type-box {
  border: 1px solid $card-border-color;
  border-radius: 3px;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: 300;
  font-size: 15px;
  position: relative;
  text-transform: capitalize;
  margin-right: 2px;
  .selected-triangle {
    margin: 0;
    display: none;
  }
  @media (hover: hover) {
    &:hover {
      border: 1px solid $accent-color;
      color: $accent-color;
    }
  }
  &.active {
    border: 1px solid $accent-color;
    color: $accent-color;
    .selected-triangle {
      display: block;
    }
    .selected-triangle:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background: linear-gradient(to bottom left, $accent-color 50%, transparent 50%);
      border-top-right-radius: 1px;
    }
  }
}
.highlight-text {
  color: #05004e;
}
.filter-date-range {
  input[type="date"]::-webkit-clear-button {
    display: none;
  }
}
.rating-overview {
  font-size: 20;
  span {
    color: $accent-color;
  }
}
.svg-icon {
  svg {
    height: 150px;
    width: 100%;
    fill: $accent-color;
    color: $accent-color;
  }
  @media (max-width: 480px) {
    width: auto;
    svg {
      height: auto;
    }
  }
}

.personbox {
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  border-radius: 80px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fffdd0;
  transition: transform 0.2s;
  position: relative;
}

.personimg {
  width: 30%;
  @media (max-width: 1200px) {
    order: 1;
    width: 100%;
  }
}

.persontextbox {
  width: 70%;
  @media (max-width: 1200px) {
    order: 2;
    width: 100%;
  }
}

.person-image {
  max-width: 350px;
  // height: 350px;
  margin-right: 10px;
}

.persontext {
  text-align: justify;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  padding: 15px;
  @media (max-width: 556px) {
    font-size: 20px;
    margin-top: 10px;
  }
}

@media (max-width: 992px) {
  .personbox {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 10px;
  }

  .person-image {
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.company-achievements {
  margin-bottom: 10rem;
}

.achievement-title {
  color: #2e2e2e;
  text-align: center;
}

.achievement-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 9px;
  padding: 6px;
}

.achievement-item {
  text-align: center;
}

.achievement-content {
  height: 150px;
  border: 2px solid #ff3333;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.achievement-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}

.achievement-content p {
  margin: 0;
  color: #000;
  font-size: 14px;
}

@media (max-width: 1200px) {
  .achievement-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding: 10px;
  }

  .achievement-item {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .achievement-content {
    height: 190px;
    border: 2px solid #da1f26;
    border-radius: 30px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
  }

  .achievement-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }

  .achievement-content p {
    font-size: 17px;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .achievement-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .achievement-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 12px;
  }

  .achievement-content {
    height: 200px;
    width: 250px;
  }

  .achievement-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }

  .achievement-content p {
    font-size: 18px;
  }
}
.vmbox-container {
  margin-bottom: 10rem;
  display: flex;
  border: 1px solid #00000000;
  border-radius: 60px;
  padding: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fffdd0;
  transition: transform 0.2s;
}

.vmbox-item {
  flex: 1;
  padding: 1rem;
}

.vmbox-title {
  color: #993300;
  font-size: 24px;
  text-align: center;
}

.vmbox-text {
  font-size: 20px;
  text-align: center;
}

.vmbox-item:first-child {
  border-right: 1px solid black;
}

@media (max-width: 900px) {
  .vmbox-container {
    flex-direction: column;
    align-items: center;
  }

  .vmbox-item:first-child {
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .vmbox-item {
    padding: 15px 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .vmbox-title {
    font-size: 20px;
  }

  .vmbox-text {
    font-size: 16px;
  }
}

.corevalues-container {
  background-color: #ffe4e1;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 10rem;
}

.corevalues-title {
  margin-top: 0 !important;
  color: #2e2e2e;
  text-align: center;
}

.corevalues {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 20px;
  // padding-bottom: 20px;
  transition: transform 0.2s;
  text-align: center;

  .corevalue-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.corevalue-icon {
  width: 90px;
  height: 90px;
}

.corevaluestext {
  color: black;
  margin-left: 5px;
  margin-top: 10px;
  font-size: 20px;
}

@media (max-width: 992px) {
  .corevalues {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .corevalues {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .corevalues {
    grid-template-columns: 1fr;
  }
}

.certified-farm-container {
  text-align: center;
  margin-bottom: 10rem;
}

.certified-farm-title {
  color: #2e2e2e;
}

.certifiedbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: content-box;
}

.farmcircle-box {
  width: 260px;
  height: 220px;
  border-radius: 10%;
  background-color: #fffdd0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 15.1px;
  margin: 10px 20px;
  padding: 10px;

  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.arrow-icon {
  width: 60px;
  height: 60px;
}

@media (max-width: 1330px) {
  .certifiedbox {
    display: inline-block;
    flex-wrap: wrap;
  }

  .arrow-icon {
    width: 60px;
    height: 60px;
  }

  .farmcircle-box {
    margin: 10px 0;
    font-size: 14px;
  }
  @media (max-width: 1330px) {
    .hide-on-small-screen {
      display: none;
    }
  }
}

.certified-no-borderbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

.feature-title {
  color: #2e2e2e;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0 !important;
}

.feature-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  // margin-bottom: 10rem;
}

.circleboxblue,
.circleboxred {
  width: 200px;
  height: 200px;
  border-radius: 20%;
  color: black;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
  font-size: 19px;
  font-weight: bold;
  p {
    margin-bottom: 0px;
  }
  .numbericon {
    width: 20px;
    height: 20px;
  }
}

.circleboxblue {
  background-color: #ffe4e1;
}

.circleboxred {
  background-color: #fffdd0;
}

.distribution-merchandise-no-borderbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.distribution-merchandise-no-borderbox img,
.distribution-no-borderbox img,
.merchandising-no-borderbox img {
  width: 280px;
  height: 240px;
  border-radius: 15px;
  margin-bottom: 10px;
  object-fit: cover;
}

.distribution-text {
  color: #2e2e2e;
  text-align: center;
  margin-top: 0 !important;
}

.distribution-no-borderbox {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10rem;
}

.merchandising-no-borderbox {
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10rem;
}

.certified-no-borderbox img {
  width: 250px;
  height: 200px;
  border-radius: 15px;
}

.certification-no-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

.certification-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;

  @media (min-width: 992px) and (max-width: 1200px) {
    width: 70%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 60%;
  }

  @media (max-width: 576px) {
    display: grid;
  }
}

.certification-images img {
  width: 150px;
  height: 150px;
}

.certification-images .custom-size {
  width: 170px;
  height: 170px;
}

@media (max-width: 800px) {
  .certified-no-borderbox img {
    width: 200px;
    height: 160px;
  }

  .certification-images {
    gap: 15px;
  }

  .certification-images img {
    width: 90px;
    height: 90px;
  }

  .certification-images .custom-size {
    width: 110px;
    height: 110px;
  }
}

@media (max-width: 500px) {
  .certified-no-borderbox img {
    width: 150px;
    height: 120px;
  }

  .certification-images {
    gap: 8px;
  }

  .certification-images img {
    width: 80px;
    height: 80px;
  }

  .certification-images .custom-size {
    width: 100px;
    height: 100px;
  }
}

.milestones-container {
  margin-bottom: 10rem;
  font-family: Arial, sans-serif;
  padding: 5px;
  text-align: center;
}

.milestones {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Reduced gap to the minimum */
  justify-content: center; /* Center the milestones horizontally */
}

.milestone {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 5px;
  width: 70%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  background-color: #ffe4e1;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 576px) {
      display: block;
    }
  }

  ul li {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.milestone:hover {
  background-color: white;
}

.year {
  font-weight: bold;
  color: #cc3333;
  margin-bottom: 0px;
}

.joinus {
  display: flex;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  flex-wrap: wrap;
  align-items: center;
  // margin: 80px auto;
}

.joinus-image {
  width: 300px;
  height: 600px;
  // border-radius: 10%;
  // margin-right: 10px;
  flex: 0 0 auto;
}

.joinus-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 300px;
  width: 90%;
  // padding-left: 20px;
}

.joinus-heading,
.joinus-text {
  color: black;
  text-align: center;
}

.app-images-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.app-image {
  max-width: 80px;
  max-height: 80px;
}

@media (max-width: 768px) {
  .joinus {
    flex-direction: column;
    align-items: center;
  }

  .joinus-image {
    margin: 0 0 20px 0;
  }

  .joinus-content {
    padding-left: 0;
    max-width: none;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .joinus-image {
    width: 200px;
    height: 400px;
  }

  .app-image {
    max-width: 60px;
    max-height: 60px;
  }
}

.video-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1200px) {
    max-width: 1240px;
  }
}

.video-title {
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
  color: #7a7a7a;
  transition: all 0.5s ease;

  h6 {
    color: $accent-color;
  }
}

.slick-prev:before, .slick-next:before {
  color: $accent-color;
  opacity: 1;
  font-size: 25px;
}

.slick-prev {
  left: -30px
}