/*
// .profile-card
*/
@import "../variables";

.profile-card {
}
.profile-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profile-card__avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 16px;

    &.large {
        width: 130px;
        height: 130px;
        margin-bottom: 30px;
        cursor: pointer;
        object-fit: cover;
    }

    img {
        border-radius: 50%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.profile-card__name {
    font-weight: $font-weight-medium;
    line-height: 20px;
}
.profile-card__email {
    font-size: 15px;
    margin-bottom: 24px;
}
.avatar-section {
    text-align: center;
    @media (max-width: 767px) {
        order: 1;
    }
}
.profile-form {
    @media (max-width: 767px) {
        order: 2;
    }
}
