/*
// .cart-table
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.cart-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
    border-radius: 8px;
    font-size: 15px;
    border: 1px solid $table-border-color;

    .alert {
        padding: 7px 35px;
        background-color: #fff7ec;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        border-left: 1px solid $table-border-color;
        border-right: 1px solid $table-border-color;
    }

    .cart-table__row &.cart-table__subRow,
    .deals-box .cart-table__row {
        &:last-child {
            border-bottom: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    .product__old-price {
        font-size: 14px;
    }
    .deals-box {
        border: 1px solid $card-border-color;
        margin: 12px;
        width: calc(100% - 24px);
        font-size: 14px;
        border-radius: $card-border-radius;
        .header {
            padding: 10px;
            border-bottom: 1px solid $card-border-color;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            a {
                margin-left: 20px;
            }
            .input-check__body {
                margin-left: 0.48rem;
                margin-right: 18px;
            }
        }
    }
}
.cart-table__body {
    // background-color: #f9f9f9;
    .cart-table__row,
    .cart-table__subRow {
        // background-color: #fff;
        &:last-child {
            border-bottom: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
.cart-table__row {
    display: flex;
    align-items: center;
    width: 100%;
    // border-bottom: 1px solid $table-border-color;
}

.cart-table__subRow {
    border-bottom: 0;
}
.cart-table__product-name {
    color: inherit;
}
.cart-table__options {
    list-style: none;
    padding: 0;
    margin: 3px 0 0;
    font-size: 14px;
    color: $cart-table-options-font-color;
    line-height: 19px;

    li {
        position: relative;

        @include direction {
            #{$padding-inline-start}: 2px;
        }
    }
    // li::before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 7px;
    //     width: 4px;
    //     height: 4px;
    //     border-radius: 2px;
    //     background: currentColor;

    //     @include direction {
    //         #{$inset-inline-start}: 1px;
    //     }
    // }
}
.cart-table__subColumn {
    border-top: none;
    &.cart-table__column--checkbox {
        visibility: hidden;
    }
}
@media (min-width: breakpoint(md-start)) {
    .cart-table__column,
    .cart-table__subColumn {
        padding: 12px 18px;
        // border-top: 1px solid $table-border-color;

        &:first-child {
            @include direction {
                // #{$border-inline-start}: 1px solid $table-border-color;
            }
        }
        &:last-child {
            @include direction {
                // #{$border-inline-end}: 1px solid $table-border-color;
            }
        }
    }
    .cart-table__subColumn {
        border-top: none;
        // padding: 10px 16px;
    }
    .cart-table__head {
        font-size: 15px;
        color: $light-opposite-color;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid $table-border-color;
     
        .cart-table__column,
        .cart-table__subColumn {
            font-weight: $font-weight-medium;
        }
        .cart-table__column:first-child {
            @include direction {
                #{$border-start-start-radius}: $table-border-radius;
            }
        }
        .cart-table__column:last-child {
            @include direction {
                #{$border-start-end-radius}: $table-border-radius;
            }
        }
        .cart-table__column--remove {
            width: 40px;
        }
    }
    .cart-table__body {
        .cart-table__column {
            padding-top: 18px;
            padding-bottom: 18px;
        }
        .cart-table__subColumn {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        .cart-table__row:last-child {
            border-bottom: 0;
            .cart-table__column:first-child {
                @include direction {
                    // #{$border-end-start-radius}: $table-border-radius;
                }
            }
        }
        .cart-table__subRow:last-child {
            .cart-table__subColumn {
                // border-bottom: 1px solid $table-border-color;
            }
        }
    }
    .cart-table__column--checkbox {
        display: flex;
        align-items: center;
    }
    .cart-table__column--product {
        width: 46%;
    }
    .cart-table__column--label {
        justify-content: flex-end;
    }

    .cart-table__column--image {
        // width: 1px;
        width: 110px;
        text-align: center;

        .product-image__img {
            object-fit: cover;
            border-radius: 4px;
        }

        @include direction {
            #{$padding-inline-end}: 4px;
        }

        .product-image {
            width: 80px;
        }
    }
    .cart-table__column--price {
        width: 170px;
        display: flex;
        white-space: nowrap;
        flex-wrap: wrap;

        .product__old-price {
            font-size: 14px;
        }
        @include direction {
            text-align: $inline-end;
        }
    }
    .cart-table__column--quantity {
        text-align: center;
        width: 20%;
        // width: 1px;
        @include direction {
            #{$padding-inline-start}: 50px;
        }
    }
    .cart-table__column--total {
        width: 170px;

        @include direction {
            text-align: $inline-end;
        }
    }
    .cart-table__column--remove {
        // width: 1px;
        padding: 0;
        padding-right: 10px;
    }
    .cart-table__column--checkbox {
        // width: 10px;
    }
}
@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .cart-table__column--quantity {
        @include direction {
            #{$padding-inline-start}: 40px;
        }
    }
    .cart-table__column--product {
        width: 40%;
    }
    .cart-table__column--price,
    .cart-table__column--total {
        white-space: unset;
        flex-wrap: wrap;
        width: 130px;
        .product__old-price {
            margin-left: 0;
        }
    }
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .cart-table__column--quantity {
        @include direction {
            #{$padding-inline-start}: 0;
            #{$padding-inline-end}: 0;
        }
    }
    .cart-table__column--product {
        width: 30%;
    }
    .cart-table__column--price,
    .cart-table__column--total {
        width: auto;
        flex-wrap: wrap;
        .product__old-price {
            margin-left: 0;
        }
    }
    .cart-table__column--remove {
        @include direction {
            #{$padding-inline-start}: 0;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .cart-table {
        display: block;
    }
    .cart-table__head {
        display: none;
    }
    .cart-table__body {
        display: flex;
        flex-wrap: wrap;
    }
    .cart-table__row{
        position: relative;
        width: 100%;
        display: block;
        // border: 1px solid $cart-table-card-border-color;

        & + & {
            margin-top: 15px;
        }
    }
    
    .cart-table__column,
    .cart-table__subColumn {
        display: block;
        padding: 0;
    }
    .cart-table__column--image {
        padding: 20px 20px 12px;
        text-align: center;

        .product-image {
            width: 100%;
            max-width: 160px;
            margin: 0 auto;
            .product-image__body {
                padding-bottom: 0;
                height: 120px;
                .product-image__img {
                    object-fit: contain;
                }
            }
        }
    }
    .cart-table__column--product {
        text-align: center;
        // border-bottom: 1px solid $cart-table-card-border-color;
        padding: 0 20px 18px;
    }
    .cart-table__column--variants,
    .cart-table__column--price,
    .cart-table__column--quantity,
    .cart-table__column--total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 20px;
        white-space: pre;

        &::before {
            font-weight: $font-weight-medium;
            width: 55%;
            flex-shrink: 0;
            content: attr(data-title) ": ";

            @include direction {
                text-align: $inline-end;
            }
        }
    }
    .cart-table__column--label {
        padding: 10px;
        display: flex;
        justify-content: center;
        .round-label {
            margin-left: 0;
        }
    }
    .cart-table__column--quantity .input-number {
        width: 110px;
    }
    .cart-table__column--price {
        padding-top: 18px;
        .product__old-price {
            display: none;
        }
    }
    .cart-table__column--total {
        padding-bottom: 18px;
    }
    .cart-table__column--remove {
        position: absolute;
        top: 5px;

        @include direction {
            #{$inset-inline-end}: 5px;
        }
    }
    .cart-table__options li::before {
        display: none;
    }
}

// for inactive cart items
.cart-table__row {
    &.inactive {
        .product-image,
        .cart-table__product-name,
        .cart-table__options,
        .input-check__body,
        .cart-table__column--price,
        .cart-table__column--quantity,
        .cart-table__column--total {
            opacity: 0.5;
            pointer-events: none;
        }
        .input-check__body,
        .input-number {
            visibility: hidden;
        }
    }
}

