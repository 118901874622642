/*
// .modal
*/
@import '../variables';


.modal {
    -webkit-overflow-scrolling: touch;
}
.modal-content {
    border: none;
    border-radius: $modal-border-radius;
    box-shadow: $modal-shadow;
}
.modal-image {
    object-fit: contain;
    max-height: 400px;
    width: 100%;
}
